import { useRouteError } from "react-router-dom";
import { captureException, showReportDialog } from "@sentry/react";
import { memo, useEffect } from "react";
import ErrorFallback from "../error-fallback";

/**
 * A component that captures the error from the route and sends it to Sentry.
 */
export function SentryErrorFallback() {
    const error = useRouteError();

    useEffect(() => {
        const eventId = captureException(error);
        showReportDialog({ eventId: eventId ?? undefined });
    }, [error]);

    return <ErrorFallback />;
}

export default memo(SentryErrorFallback);
